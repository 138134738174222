import React, {useState} from 'react';
import NumberControl from "@rio-cloud/rio-uikit/lib/es/NumberControl";
import InputOTA from "~/features/base/components/forms/InputOTA";
import {FormattedMessage} from 'react-intl';

interface Mileage {
    min: number;
    max: number;
}

interface MileageFormItemProps {
    mileage: Mileage;
    onChange: (mileage: Mileage) => void;
}

const MileageFormItem: React.FC<MileageFormItemProps> = ({mileage, onChange}) => {

    const [value, setValue] = useState(mileage);

    return (
        <div className={"display-flex flex-row gap-20"}>
            <div className='form-group width-50pct '>
                <InputOTA
                    title={<FormattedMessage id='Min Mileage (km)'/>}
                    type='number'
                    minValue={0}
                    className='form-control'
                    value={value?.min}
                    placeholder={'Enter min mileage'}
                    onChange={(e)=> {
                        onChange({...value, min: e.target.value})
                        setValue({...value, min: e.target.value})
                    }}
                />
            </div>
            <div className='form-group width-50pct '>
            <InputOTA
                title={<FormattedMessage id='Max Mileage (km)'/>}
                type='number'
                className='form-control'
                value={value?.max}
                placeholder={'Enter max mileage'}
                onChange={(e)=> {
                    onChange({...value, max: e.target.value})
                    setValue({...value, max: e.target.value})
                }}
            />
            </div>


        </div>
    );
};

export default MileageFormItem;
